import { ref, watch } from "@vue/composition-api";

export default function useOrderSourceListHandler(props, emit) {
    // ------------------------------------------------
    // orderSourceLocal
    // ------------------------------------------------
    const orderSourceLocal = ref(JSON.parse(JSON.stringify(props.orderSource.value)));
    const resetOrderSourceLocal = () => {
        orderSourceLocal.value = JSON.parse(JSON.stringify(props.orderSource.value));
    };
    watch(props.orderSource, () => {
        resetOrderSourceLocal();
    });

    // ------------------------------------------------
    // isEventHandlerSidebarActive
    // * Clear form if sidebar is closed
    // ! We can hide it using @hidden event
    // ------------------------------------------------
    // watch(props.isEventHandlerSidebarActive, val => {
    //   // ? Don't reset event till transition is finished
    //   if (!val) {
    //     setTimeout(() => {
    //       clearForm.value()
    //     }, 350)
    //   }
    // })

    const onSubmit = () => {
        const orderSourceData = JSON.parse(JSON.stringify(orderSourceLocal));

        // * If event has id => Edit Event
        // Emit event for add/update event
        if (props.orderSource.value.id) emit("update-order-source", orderSourceData.value);
        else emit("add-order-source", orderSourceData.value);

        // Close sidebar
        emit("update:is-order-source-handler-sidebar-active", false);
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    // ------------------------------------------------
    // guestOptions
    // ------------------------------------------------

    /* eslint-disable global-require */
    // const assigneeOptions = [
    //   { avatar: require('@/assets/images/avatars/1-small.png'), fullName: 'Jane Foster' },
    //   { avatar: require('@/assets/images/avatars/3-small.png'), fullName: 'Donna Frank' },
    //   { avatar: require('@/assets/images/avatars/5-small.png'), fullName: 'Gabrielle Robertson' },
    //   { avatar: require('@/assets/images/avatars/7-small.png'), fullName: 'Lori Spears' },
    //   { avatar: require('@/assets/images/avatars/9-small.png'), fullName: 'Sandy Vega' },
    //   { avatar: require('@/assets/images/avatars/11-small.png'), fullName: 'Cheryl May' },
    // ]

    /* eslint-enable global-require */

    return {
        orderSourceLocal,
        resetOrderSourceLocal,

        // UI
        onSubmit,
    };
}
