import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        // eslint-disable-next-line no-unused-vars
        fetchOrderSources(_, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/admin/orderSource", {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addOrderSource(ctx, orderSourceData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/admin/orderSource", orderSourceData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateOrderSource(ctx, orderSourceData) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/admin/orderSource/${orderSourceData.id}`, orderSourceData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteOrderSource(ctx, { orderSourceId }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/admin/orderSource/${orderSourceId}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    },
};
