<template>
    <div>
        <!-- Order Source Handler -->
        <order-source-list-handler
            v-model="isOrderSourceHandlerSidebarActive"
            :order-source="orderSource"
            :clear-order-source-data="clearOrderSourceData"
            @remove-order-source="removeOrderSource"
            @add-order-source="addOrderSource"
            @update-order-source="updateOrderSource"
        />

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button variant="primary" @click="isOrderSourceHandlerSidebarActive = true">
                                <span class="text-nowrap">Add Order Source</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchOrderSources"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: ID -->
                <template #cell(id)="data">
                    <span class="font-weight-bold">#{{ data.item.id}}</span>
                </template>

                <!-- Column: Name -->
                <template #cell(name)="data">
                    <div class="text-nowrap">
                        <b-link
                            class="font-weight-bold d-block text-nowrap"
                            @click="handleOrderSourceClick(data.item)"
                        >
                            {{ data.item.name }}
                        </b-link>
                    </div>
                </template>

                <!-- Column: Color -->
                <template #cell(variant)="data">
                    <b-badge
                        pill
                        :variant="`light-${data.item.color}`"
                        class="text-capitalize"
                    >
                        {{ data.item.color }}
                    </b-badge>
                </template>

                <!-- Column: Create Date -->
                <template #cell(createDate)="data">
                    <span class="text-nowrap text-capitalize">
                        {{ data.item.createDate | formatDate }}
                    </span>
                </template>

                <template #cell(updatedDate)="data">
                    <span class="text-nowrap text-capitalize">
                        {{ data.item.updatedDate | formatDate }}
                    </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item :id="`user-row-${data.item.id}-sync-icon`" @click="syncuser(data.item.id)">
                            <feather-icon icon="RefreshCwIcon" />
                            <span class="align-middle ml-50">Sync</span>
                        </b-dropdown-item>

                        <b-dropdown-item disabled>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-row v-if="totalUsers === 0" class="mx-2 d-flex justify-content-center"
            ><span class="font-weight-bold">No Records Found</span></b-row
            >
            <div v-if="tableLoading" class="text-center my-1">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            </div>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                        >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol, // BFormInput,
    BButton,
    BTable,
    BSpinner,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import useOrderSourceList from "./useOrderSourceList";
import orderSourceStoreModule from "./orderSourceStoreModule";
import OrderSourceListHandler from "@/views/admin/order-source/OrderSourceListHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";

export default {
    components: {
        OrderSourceListHandler,
        BCard,
        BRow,
        BCol,
        // BFormInput,
        BSpinner,
        BButton,
        BTable,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const toast = useToast();
        const USER_APP_STORE_MODULE_NAME = "order-source";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            store.registerModule(USER_APP_STORE_MODULE_NAME, orderSourceStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            }
        });

        const blankOrderSource = {
            name: "",
            label: "",
            variant: "",
        }

        const orderSource = ref(JSON.parse(JSON.stringify(blankOrderSource)));
        const clearOrderSourceData = () => {
            orderSource.value = JSON.parse(JSON.stringify(blankOrderSource));
        };

        const handleOrderSourceClick = orderSourceData => {
            orderSource.value = orderSourceData;
            isOrderSourceHandlerSidebarActive.value = true;
        }

        const isOrderSourceHandlerSidebarActive = ref(false);

        const departmentOptions = [
            {
                label: "Technology",
                value: "Technology",
            },
            {
                label: "Marketing",
                value: "Marketing",
            },
            {
                label: "Sales",
                value: "Sales",
            },
            {
                label: "Product",
                value: "Product",
            },
        ];

        const {
            fetchOrderSources,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            tableLoading,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useOrderSourceList();

        const addOrderSource = val => {
            if(!val) return;
            store.dispatch("order-source/addOrderSource", val)
                .then(()=> {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order Source Added",
                            icon: "CheckCircleIcon",
                            variant: "success"
                        }
                    })
                })
                .then(()=> {
                    refetchData();
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order Status Add Failed",
                            icon: "AlertTriangleIcon",
                            variant: "danger"
                        }
                    })
                });

        };

        const removeOrderSource = () => {
            store.dispatch("order-source/deleteOrderSource", {orderSourceId: orderSource.value.id})
                .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order Source Removed",
                            icon: "CheckCircleIcon",
                            variant: "success"
                        }
                    })
                })
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    refetchData();
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order Source Remove Failed",
                            icon: "AlertTriangleIcon",
                            variant: "danger"
                        }
                    })
                });
        };


        const updateOrderSource = orderSourceData => {
            store.dispatch("order-source/updateOrderSource", orderSourceData)
                .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order Source Updated",
                            icon: "CheckCircleIcon",
                            variant: "success"
                        }
                    });
                })
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    refetchData();
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Order Source Update Failed",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                })
        };




        return {
            // Sidebar
            isOrderSourceHandlerSidebarActive,

            fetchOrderSources,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            tableLoading,
            // Filter
            avatarText,

            departmentOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,

            // actions
            addOrderSource,
            removeOrderSource,
            updateOrderSource,
            blankOrderSource,
            orderSource,
            clearOrderSourceData,
            handleOrderSourceClick,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
